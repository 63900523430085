
import React from 'react'
import {useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
//components
import PageTitle from '../../components/common/pageTitle';
import MainContent from '../../components/dashboard/MainContent';
//service
import DashboardService from '../../../service/dashboard/Dashboard';
import Spinner from '../../shared/Spinner'
import { withRouter } from 'react-router';
import companyName from '../../../store/mobxStore/companyName';
import {useSelector} from 'react-redux';

const Home = (props) => {
    const cplan = useSelector(state=>state.ProjectConfig.mlmPlan);




    

  

    const [state, setState] = useState({
        memberStatus: false,
        loading: true,
        alert: {
            show: false,
            varient: '',
            message: ''
        }
    })

    const service = new DashboardService();
    const { t } = props


  

useEffect(() => {
        const service = new DashboardService();
        const { state } = props.location;
        if (state) {
            if (state.error) {
                setState(prevstate => ({
                    ...prevstate,
                    alert: {
                        show: true,
                        varient: 'danger',
                        message: 'validation.' + state.message
                    }
                }))
            }
        }
        service.DashboardLoad().then((res) => {
            if (res.status) {
                    setState(prevState => ({
                        ...prevState,
                        loading: false,
                        ...res.data
                    }));    
            } else {
                props.history.push('/logout');
            }
           
        })
    // },[]) 
   
     }, [props.history, props.location])

    const FiterGraph = (range) => {
        service.JoiningGraphFilter(range).then((res) => {
            if (res.status) {
                setState(prev => ({
                    ...prev,
                    joining_graph_data_new: res.data.joining_graph_data_new
                }))
            } else {
                props.history.push('/logout');
            }
        })
    }

    const TilesFitler = (type, range) => {
        service.TileFilter(type, range).then((res) => {
            if (res.status) {
                let newData = state.tiles.map(object => {
                    if (object.text === type) {
                        return res.data
                    } else {
                        return object
                    }
                })
                setState(prevState =>({
                    ...prevState,
                    tiles: newData
                }))
            } else {
                props.history.push('/logout');
            }
        })
    }

    const closeAlert = () => {
        setState(prevState =>({
            ...prevState,
            alert: {
                show: false,
                message: '',
                varient: ''
            }
        }))
    }



    return (
        <div>
            {/* {state.loading ?
                <Spinner />
            : */}
        
            <React.Suspense>
                <Helmet>
                    <title>{companyName.companyName} | {t('Sidemenu.overview')}</title>
                </Helmet>
                {/* <PageTitle title="overview" buttonOn={false} /> */}
              
                <MainContent
                    {...state}
                    filterChange={TilesFitler}
                    chartfilterChange={FiterGraph}
                    alertClose={closeAlert}
                    style={{marginTop:'-2%',}}
                    lodervalue={state.loading}
                />
                
            </React.Suspense>
        </div>
    )


}
export default withTranslation()((withRouter(Home)))